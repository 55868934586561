// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Test-id[data-v-2b6339ec] {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.starfield.debug[data-v-2b6339ec] {
  z-index: 2;
}
.starfield[data-v-2b6339ec] {
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.starfield canvas[data-v-2b6339ec] {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
h1[data-v-2b6339ec] {
  color: white;
  position: relative;
  text-align: center;
  font-family: "StarWars";
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/Starfield.vue","webpack://./Starfield.vue"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,WAAA;EAEA,kBAAA;EACA,OAAA;EACA,MAAA;EACA,SAAA;EACA,QAAA;ACFF;ADKA;EACE,UAAA;ACFF;ADMA;EACE,YAAA;EACA,OAAA;EACA,SAAA;EACA,UAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;EACA,UAAA;ACHF;ADMA;EACE,YAAA;EACA,OAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;ACHF;ADYA;EACE,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,uBAAA;ACTF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n#Test-id {\n  height: 100%;\n  width: 100%;\n\n  position: absolute;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  right: 0;\n}\n\n.starfield.debug {\n  z-index: 2;\n}\n\n\n.starfield {\n  height: 100%;\n  left: 0;\n  margin: 0;\n  padding: 0;\n  position: absolute;\n  top: 0;\n  width: 100%;\n  z-index: 0;\n}\n\n.starfield canvas {\n  height: 100%;\n  left: 0;\n  position: absolute;\n  top: 0;\n  width: 100%;\n}\n\n//@font-face {\n  //font-family: StarWars;\n  //src: url('@/fonts/Starjhol.ttf') format('truetype');\n  //font-weight: normal;\n//}\n\nh1 {\n  color: white;\n  position: relative;\n  text-align: center;\n  font-family: 'StarWars';\n}\n","#Test-id {\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  right: 0;\n}\n\n.starfield.debug {\n  z-index: 2;\n}\n\n.starfield {\n  height: 100%;\n  left: 0;\n  margin: 0;\n  padding: 0;\n  position: absolute;\n  top: 0;\n  width: 100%;\n  z-index: 0;\n}\n\n.starfield canvas {\n  height: 100%;\n  left: 0;\n  position: absolute;\n  top: 0;\n  width: 100%;\n}\n\nh1 {\n  color: white;\n  position: relative;\n  text-align: center;\n  font-family: \"StarWars\";\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
