<template>
  <Starfield>
    <iRow vertical-align="middle" align="center">
      <iColumn align="center" :width="800">
        <iHeading
          font-size="displayLarge"
          variant="accent"
          :style-overrides="{marginBottom: 'none'}"
        >
          R2-D2
        </iHeading>
        <iHeading variant="accent">
          release 2 - deploy 2
        </iHeading>
        <iTextInput v-model="email" placeholder="Email" @enter="submitLogin" />
        <iTextInput
          v-model="password"
          placeholder="Password"
          type="password"
          @enter="submitLogin"
        />
        <iText v-if="loginErrorMessage" variant="error">
          {{ loginErrorMessage }}
        </iText>
        <iRow width="hug" align="center">
          <iButton
            variant="secondary"
            :is-loading="loadingLogin"
            loading-spinner-position="inside"
            @click="submitLogin"
          >
            Login
          </iButton>
        </iRow>
      </iColumn>
    </iRow>
  </Starfield>
</template>

<script>

import { mapActions, mapState } from "pinia";
import { useGeneralStore } from "@/stores/general-store";
import Starfield from "@/components/Starfield";

export default {
  name: "Login",
  components: { Starfield },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    ...mapState(useGeneralStore, [
      "loginErrorMessage",
      "loadingLogin",
    ]),
  },
  methods: {
    ...mapActions(useGeneralStore, [
      "login",
    ]),
    submitLogin() {
      if (!this.email || !this.password) {
        return;
      }
      this.login(this.email, this.password);
    },
  },
};
</script>
