<template>
  <div :id="ids.vid" class="starfield" :class="{'debug' : debug}">
    <canvas :id="ids.cid" style="z-index: -1;" />
    <div style="height: 100vh; overflow-y: scroll; overflow-x: hidden; padding-left: 40px; padding-right: 40px">
      <slot />
    </div>
    <StarfieldDebug @stop="stop" @start="start" @reset="reset" />
  </div>
</template>

<script>

import fastdom from "fastdom";
import mainloop from "mainloop.js";
import Stats from "stats.js";

import { useStarfieldStore } from "@/stores/starfield-store";
import { mapActions, mapState, mapWritableState } from "pinia";
import StarfieldDebug from "@/components/StarfieldDebug.vue";

export default {
  name: "Starfield",
  components: { StarfieldDebug },
  props: {},
  data() {
    return {
      stats: new Stats(),
      // Mouse and cursor
      // mouse: {
      //   x: 0,
      //   y: 0
      // },

      // cursor: {
      //   x: 0,
      //   y: 0
      // },
    };
  },
  computed: {
    ...mapWritableState(useStarfieldStore, [
      "debug",
      "hyperspace",
      "warpFactor",
      "easing",
      "quantity",
      "starColor",
      "bgColor",
      "speed",
      "state",
    ]),
    ...mapState(useStarfieldStore, ["compColors", "compSpeed", "colors", "ids", "ratio"]),
  },
  methods: {
    ...mapActions(useStarfieldStore, { initStore: "init", resetStore: "reset" }),
    init() {
      this.initStore();
      this.starz();

      if (this.debug) {
        this.initStatsPanel();
      }
    },
    initStatsPanel() {
      this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
      document.body.appendChild(this.stats.dom);
      this.stats.dom.style.right = "20px";
      this.stats.dom.style.left = "auto";
    },
    starz() {
      let sd = {
        viewportWidth: 0,
        viewportHeight: 0,
        element: null,
        context: null,


        canvasWidth: 0,
        canvasHeight: 0,

        xAxis: 0,
        yAxis: 0,
        zAxis: 0,

        // Star data
        star: {
          // Etc
          colorRatio: 0,

          // The stars
          starsArray: [],
        },

        prevTime: 0,
      };

      // DOM interactions
      let dom = {
        measure: {
          viewport: () => {
            fastdom.measure(() => {
              sd.viewportWidth = this.$el.clientWidth;
              sd.viewportHeight = this.$el.clientHeight;

              // Set up axes
              sd.xAxis = Math.round(sd.viewportWidth / 2);
              sd.yAxis = Math.round(sd.viewportHeight / 2);
              sd.zAxis = (sd.viewportWidth + sd.viewportHeight) / 2;

              sd.star.colorRatio = 1 / sd.zAxis;

              // if (this.cursor.x === 0 || this.cursor.y === 0) {
              //   // Initialize cursor position
              //   this.cursor.x = sd.xAxis
              //   this.cursor.y = sd.yAxis
              // }
              //
              // if (this.mouse.x === 0 || this.mouse.y === 0) {
              //   // Initialize mouse position
              //   this.mouse.x = this.cursor.x - sd.xAxis
              //   this.mouse.y = this.cursor.y - sd.yAxis
              // }
            });

            fastdom.catch = function () {
              return;
            };
          },
        },
      };

      // Star functions
      let starFunctions = {
        /**
         * Set up the viewport
         */
        viewport() {
          dom.measure.viewport();
        },

        /**
         * Set up the canvas
         */
        canvas: () => {
          dom.measure.viewport();

          // Set up context
          let c = document.getElementById(this.ids.cid);
          sd.context = c.getContext("2d");

          // Adjust canvas dimensions
          sd.context.canvas.width = sd.viewportWidth;
          sd.context.canvas.height = sd.viewportHeight;

          // Set up canvas colors
          sd.context.fillStyle = this.colors.fill;
          sd.context.strokeStyle = this.starColor;
        },

        /**
         * Initialize the array of stars and their positions, if it hasn't been done already
         */
        bigBang: () => {
          let areStarsInitialized = sd.star.starsArray.length === this.quantity;
          let starsRandomized = false;

          if (sd.star.starsArray.length > 0) {
            if (sd.star.starsArray[0][0] !== 0 || sd.star.starsArray[0][0] !== Infinity) {
              starsRandomized = true;
            }
          }

          let boom = () => {
            sd.star.starsArray = new Array(this.quantity);

            // Set up the star array
            for (let i = 0; i < this.quantity; i++) {
              sd.star.starsArray[i] = new Array(8);

              // Give each star random positions on the canvas
              sd.star.starsArray[i][0] = Math.random() * sd.viewportWidth * 2 - sd.xAxis * 2;
              sd.star.starsArray[i][1] = Math.random() * sd.viewportHeight * 2 - sd.yAxis * 2;
              sd.star.starsArray[i][2] = Math.round(Math.random() * sd.zAxis);
              sd.star.starsArray[i][3] = 0;
              sd.star.starsArray[i][4] = 0;
              sd.star.starsArray[i][5] = 0; // prev x
              sd.star.starsArray[i][6] = 0; // prev y
              sd.star.starsArray[i][7] = true; // test var
            }
          };

          if (!areStarsInitialized) {
            boom();
          } else if (areStarsInitialized && !starsRandomized) {
            boom();
          }
        },

        /**
         * Updates canvas el dimensions, adjusts star coords proportionally
         */
        resize: () => {
          if (this.resizing) {
            // Save old dimensions and star positions
            let oldStar = sd.star;
            dom.measure.viewport();

            sd.canvasWidth = sd.context.canvas.width;
            sd.canvasHeight = sd.context.canvas.height;

            // Only resize if context width/height !== container width/height
            if (sd.canvasWidth !== sd.viewportWidth || sd.canvasHeight !== sd.viewportHeight) {
              sd.xAxis = Math.round(sd.viewportWidth / 2);
              sd.yAxis = Math.round(sd.viewportHeight / 2);
              sd.zAxis = (sd.viewportWidth + sd.viewportHeight) / 2;

              sd.star.colorRatio = 1 / sd.zAxis;

              // Get ratio of new dimensions to old dimensions
              let rw = sd.viewportWidth / sd.canvasWidth;
              let rh = sd.viewportHeight / sd.canvasHeight;

              // Update context dimensions
              sd.context.canvas.width = sd.viewportWidth;
              sd.context.canvas.height = sd.viewportHeight;

              // Recalculate star positions
              if (!sd.star.starsArray.length) {
                starFunctions.bigBang();
              } else {
                for (var i = 0; i < this.quantity; i++) {
                  sd.star.starsArray[i];
                  sd.star.starsArray[i][0] = oldStar.starsArray[i][0] * rw;
                  sd.star.starsArray[i][1] = oldStar.starsArray[i][1] * rh;

                  sd.star.starsArray[i][3] = sd.xAxis + sd.star.starsArray[i][0] / sd.star.starsArray[i][2] * this.ratio.computed;
                  sd.star.starsArray[i][4] = sd.yAxis + sd.star.starsArray[i][1] / sd.star.starsArray[i][2] * this.ratio.computed;
                }
              }

              // Reset canvas colors (context resets completely when resized)
              sd.context.fillStyle = this.colors.fill;
              sd.context.strokeStyle = this.starColor;

              this.resizing = false;
            }
          }
        },

        anim: {
          init() {
            mainloop
                .setBegin(starFunctions.anim.begin)
                .setUpdate(starFunctions.anim.update)
                .setDraw(starFunctions.anim.draw)
                .setEnd(starFunctions.anim.end);
          },

          /**
           * Kick off the animation loop
           */
          start: () => {
            mainloop.start();
            this.resizing = true;
          },

          /**
           * Begin frame
           */
          begin: () => {
            if (this.debug) {
              this.stats.begin();
            }

            starFunctions.resize();

            if (sd.prevTime === 0) {
              sd.prevTime = Date.now();
            }

            this.state.running = mainloop.isRunning();
          },

          /**
           * Calculate the position of each star
           */
          update: () => {
            // this.mouse.x = (this.cursor.x - sd.xAxis) / this.easing
            // this.mouse.y = (this.cursor.y - sd.yAxis) / this.easing

            if (sd.star.starsArray.length > 0) {
              for (let index = 0; index < this.quantity; index++) {
                sd.star.starsArray[index][7] = true;
                sd.star.starsArray[index][5] = sd.star.starsArray[index][3];
                sd.star.starsArray[index][6] = sd.star.starsArray[index][4];
                // sd.star.starsArray[index][0] += this.mouse.x >> 4

                // X coords
                if (sd.star.starsArray[index][0] > sd.xAxis << 1) {
                  sd.star.starsArray[index][0] -= sd.viewportWidth << 1;
                  sd.star.starsArray[index][7] = false;
                }
                if (sd.star.starsArray[index][0] < -sd.xAxis << 1) {
                  sd.star.starsArray[index][0] += sd.viewportWidth << 1;
                  sd.star.starsArray[index][7] = false;
                }

                // Y coords
                // sd.star.starsArray[index][1] += this.mouse.y >> 4
                if (sd.star.starsArray[index][1] > sd.yAxis << 1) {
                  sd.star.starsArray[index][1] -= sd.viewportHeight << 1;
                  sd.star.starsArray[index][7] = false;
                }
                if (sd.star.starsArray[index][1] < -sd.yAxis << 1) {
                  sd.star.starsArray[index][1] += sd.viewportHeight << 1;
                  sd.star.starsArray[index][7] = false;
                }

                // Z coords
                sd.star.starsArray[index][2] -= this.compSpeed.lyph;
                if (sd.star.starsArray[index][2] > sd.zAxis) {
                  sd.star.starsArray[index][2] -= sd.zAxis;
                  sd.star.starsArray[index][7] = false;
                }
                if (sd.star.starsArray[index][2] < 0) {
                  sd.star.starsArray[index][2] += sd.zAxis;
                  sd.star.starsArray[index][7] = false;
                }

                sd.star.starsArray[index][3] = sd.xAxis + sd.star.starsArray[index][0] / sd.star.starsArray[index][2] * this.ratio.computed;
                sd.star.starsArray[index][4] = sd.yAxis + sd.star.starsArray[index][1] / sd.star.starsArray[index][2] * this.ratio.computed;
              }
            }
          },

          draw: () => {
            sd.context.fillStyle = this.colors.fill;
            sd.context.fillRect(0, 0, sd.viewportWidth, sd.viewportHeight);
            sd.context.strokeStyle = this.starColor;

            if (sd.star.starsArray.length) {
              for (let index = 0; index < this.quantity; index++) {
                if (sd.star.starsArray[index][5] > 0 &&
                  sd.star.starsArray[index][5] < sd.viewportWidth &&
                  sd.star.starsArray[index][6] > 0 &&
                  sd.star.starsArray[index][6] < sd.viewportHeight &&
                  sd.star.starsArray[index][7]) {
                  sd.context.lineWidth = (1 - sd.star.colorRatio * sd.star.starsArray[index][2]) * 2;
                  sd.context.beginPath();
                  sd.context.moveTo(sd.star.starsArray[index][5], sd.star.starsArray[index][6]);
                  sd.context.lineTo(sd.star.starsArray[index][3], sd.star.starsArray[index][4]);
                  sd.context.stroke();
                  sd.context.closePath();
                }
              }
            }
          },

          stop: () => {
            mainloop.stop();
            this.state.running = mainloop.isRunning();
          },

          end: (fps, panic) => {
            if (this.debug) {
              this.stats.end();
            }

            // Check FPS every second. If it drops too low, reduce the number of stars
            // If the stars are too few (it looks fugly), stop the animation
            let delta = Date.now() - sd.prevTime;
            if (fps < 24 && delta >= 1000) {
              this.stop();
              mainloop.resetFrameDelta();
              this.quantity = this.quantity - 25;
              this.reset();

              if (this.quantity < 64) {
                this.stop();
              }

              sd.prevTime = 0;
            }

            if (panic) {
              mainloop.resetFrameDelta();
            }
          },

          /**
           * Reset the whole kit and kaboodle
           */
          reset: () => {
            this.stop();

            // Reset dimensions
            starFunctions.resetDims();

            this.init();
          },

          destroy: () => {
            this.stop();

            starFunctions.resetDims();

            starFunctions = null;
            sd = null;
          },
        },

        resetDims() {
          sd.xAxis = 0;
          sd.yAxis = 0;
          sd.zAxis = 0;
          sd.viewportWidth = 0;
          sd.viewportHeight = 0;
          sd.canvasWidth = 0;
          sd.canvasHeight = 0;
        },
      };

      // Animation/init logic and state management
      // Check for destroy, stop, and reset first
      if (this.state.destroy) {
        this.state.destroy = false;
        starFunctions.anim.destroy();
      }

      if (this.state.stop) {
        this.state.stop = false;
        starFunctions.anim.stop();
      }

      if (this.state.reset) {
        this.state.reset = false;
        starFunctions.anim.reset();
      }

      // Initialization
      if (this.state.init) {
        this.state.init = false;
        starFunctions.viewport();
        starFunctions.anim.init();
      }

      // Set up the canvas if it isn't already
      if (this.state.canvas) {
        this.state.canvas = false;
        starFunctions.canvas();
      }

      // Animation start
      if (this.state.start) {
        this.state.start = false;
        starFunctions.anim.start();
      }

    },

    // Animation controls
    reset() {
      this.state.reset = true;
      this.starz();
    },

    stop() {
      this.state.stop = true;
      this.starz();
    },

    start() {
      this.state.start = true;
      this.starz();
    },
  },
  beforeCreate() {
    setTimeout(() => {
      this.init();
    }, 100);

  },
  beforeUnmount() {
    // Reset state
    this.resetStore();

    // Remove listeners
    this.hyperspace = false;

    this.starz();
  },
};
</script>

<style scoped>
#Test-id {
  height: 100%;
  width: 100%;

  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.starfield.debug {
  z-index: 2;
}


.starfield {
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.starfield canvas {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

//@font-face {
  //font-family: StarWars;
  //src: url('@/fonts/Starjhol.ttf') format('truetype');
  //font-weight: normal;
//}

h1 {
  color: white;
  position: relative;
  text-align: center;
  font-family: 'StarWars';
}
</style>
