<template>
  <Starfield>
    <iColumn>
      <iRow justify-content="space-between">
        <iRow width="hug">
          <iHeading variant="accent">
            R2-D2
          </iHeading>
          <iHeading variant="subtle">
            /
          </iHeading>
          <iHeading variant="subtle">
            App List
          </iHeading>
        </iRow>
        <iRow width="hug">
          <iHeading variant="accent" @click="logout">
            force quit
          </iHeading>
        </iRow>
      </iRow>
      <iRow justify-content="center">
        <iCard
          v-for="(app, appKey) in apps"
          :key="appKey"
          :width="400"
          clickable
          @click="selectApp(appKey)"
        >
          <template #header>
            <iRow justify-content="center">
              <iHeading
                variant="accent"
                font-size="extraLarge"
                align="center"
                :style-overrides="{marginBottom: 'none'}"
              >
                {{ app.app_name.toLowerCase() }}
              </iHeading>
            </iRow>
          </template>
          <template #body>
            <iRow align="center">
              <iText variant="subtle">
                {{ app.type }}
              </iText>
            </iRow>
          </template>
        </iCard>
      </iRow>
    </iColumn>
  </Starfield>
</template>

<script>

import { mapActions, mapState } from "pinia";
import { useGeneralStore } from "@/stores/general-store";
import Starfield from "@/components/Starfield";

export default {
  name: "Home",
  components: { Starfield },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["apps"]),
  },
  methods: {
    ...mapActions(useGeneralStore, [
      "logout",
    ]),
    selectApp(appName) {
      window.location.href = `/deploy/${appName}`;
    },
  },
};
</script>
